<template>
  <component :is="$route.meta.layout">
    <p>This is an About page</p>
    <router-link :to="{ name: 'Home' }">
      Go Home
    </router-link>
  </component>
</template>

<script>
export default {
  //
};
</script>
